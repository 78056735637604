import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { graphql } from "gatsby"

const StyledHeroImage = styled(Image)`
  width: 100%;
  margin: 0 auto;
`

const StyledContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
`

const StyledSectionTitle = styled.h2`
  color: #da1e35;
  font-size: 32px;
  text-align: center;
  padding: 0 20px;
`

const StyledSection = styled("div")`
  display: flex;
  flex-direction: column;

  p {
    color: #000;
    font-size: 15px;
    padding: 0 20px;
    text-align: justify;
  }
`

const OverprintPage = ({ data }) => {
  return (
    <>
      <StyledHeroImage fluid={data.hero_image_painting.childImageSharp.fluid} />
      <StyledContentWrapper
        image={data.maps_background.childImageSharp.fluid.src}
      >
        <StyledSection>
          <StyledSectionTitle>NADRUK</StyledSectionTitle>
          <p>
            Oferujemy wykonanie nadruków na materiałach metalowych i z tworzyw
            sztucznych. Specjalizujemy się w nadrukach metodą sito- i
            tampondruk. Oferta obejmuje wdrożenie każdego wzoru nadruku oraz
            kompleksową obsługę projektu w postaci pliku graficznego: wykonanie
            matryc, oprzyrządowania i nadruku.
          </p>
        </StyledSection>
      </StyledContentWrapper>
    </>
  )
}

export const query = graphql`
  {
    maps_background: file(name: { eq: "maps_background" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    hero_image_painting: file(name: { eq: "hero_image_painting" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default OverprintPage
